<template>
  <div>
    <div>
      <el-table
          :data="tableData"
          style="width: 100%"
          max-height="350">
        <el-table-column
            prop="vtTemplateName"
            label="订阅名称"/>
        <el-table-column
            prop="vtSentNumber"
            label="通知次数"/>
      </el-table>
      <div style="margin-top: 30px; text-align: center">
        <wx-open-subscribe :template="templateList" id="subscribe-btn">
          <script type="text/wxtag-template" slot="style">
            <style>
              .subscribe-btn {
                color: #fff;
                background-color: #07c160;
                padding: 10px;
                font-size: 24px;
              }
            </style>
          </script>
          <script type="text/wxtag-template">
            <el-button class="subscribe-btn" type="primary">消息订阅</el-button>
          </script>
        </wx-open-subscribe>
      </div>
    </div>
  </div>
</template>

<script>
import {etSignature} from "@/utils/weChatUtil";
import WeChat from "../../../request/weChat";
import SystemConfig from "../../../config/SystemConfig";

export default {
  data() {
    return {
      ask: new WeChat(),
      templateList: [""],
      wechatOpenId: "",
      tableData: []
    }
  },
  created() {
    this.wechatOpenId = this.$storageHandler.getSessionStorage("openId");
    if (this.wechatOpenId == null || this.wechatOpenId == "") {
      alert("身份标识空!")
      return;
    }
    const time = new Date().getTime();
    let nonceStr = "charon101";
    let this_ = this;
    this.ask.getMpConfig().then(res => {
      if (res.code == 'success') {
        this.templateList = res.msg.templateList.map(item => item.id);
        this.$nextTick(function () {
          window.wx.config({
            debug: false,
            appId: SystemConfig.weChatSso.APPID,
            timestamp: time,
            nonceStr: nonceStr,
            signature: etSignature(res.msg.ticket, nonceStr, time, 'https://charonv.com/expand/weChat/subscribe'),
            jsApiList: ['getLocation'],
            openTagList: ['wx-open-subscribe']
          });
          window.wx.ready(function () {
            let btn = document.getElementById('subscribe-btn');
            btn.addEventListener('success', function (e) {
              let subscribeDetails = JSON.parse(e.detail.subscribeDetails);
              const list = []
              Object.keys(subscribeDetails).forEach((key) => {
                // console.log(`${key}: ${subscribeDetails[key]}`);
                let statusObj = JSON.parse(subscribeDetails[key]);
                if (statusObj.status === 'accept') {
                  list.push(key);
                }
              });
              this_.addSubNumber(list);
            });
            btn.addEventListener('error', function (e) {
              this_.$message('取消订阅!');
            });
          });
        })
      }
    })
  }
  ,
  mounted() {
    let this_ = this;
    this.ask.synSubTemplate({"openId": this.wechatOpenId}).then(res => {
      this_.ask.getMpSubscribe({"openId": this.wechatOpenId}).then(res => {
        this_.tableData = res.msg
      })
    })
  }
  ,
  methods: {
    /**
     * 根据模板以及用户新增订阅次数
     * @param templateList
     */
    addSubNumber(templateList) {
      let data = {"openId": this.wechatOpenId, "templateList": templateList, "number": 1}
      this.ask.addSubNumber(data).then(res => {
        if (res.code === 'success') {
          this.$message({
            message: '订阅成功! 次数+1',
            type: 'success'
          });
          const filteredData = this.tableData.filter((obj) => templateList.includes(obj.vtTemplateId));
          // 使用reduce方法更新vtSentNumber属性
          const updatedData = filteredData.reduce((acc, obj) => {
            obj.vtSentNumber++;
            return acc.concat(obj);
          }, []);
        }
      })
    }
  }
}
</script>
